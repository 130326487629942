$(function () {

    /*Кнопка вверх*/
    function handleScrollTop() {
        var button = $(".js-scroll-top");

        if (button.length === 0) return;
        var viewportHeight = "innerHeight" in window ? window.innerHeight
            : document.documentElement.offsetHeight;

        $(window).scroll(function () {
            var topPos = $(this).scrollTop();
            if (topPos > viewportHeight) {
                button.css("opacity", "1");

            } else {
                button.css("opacity", "0");
            }

        });

        button.click(function () {
            $('html, body').animate({
                scrollTop: 0
            }, 600);
            return false;

        });
    }

    handleScrollTop();

    /* Меню-бургер */
    function toggleBurger() {
        $('.js-toggle-button').click(function () {
            $('.js-toggle-menu').fadeToggle();
        });
    }

    toggleBurger();

    /* Выключение меню на ресайзе */
    function switchOffBurger(className) {
        $(window).resize(function () {
            $('.js-toggle-menu').fadeIn();
            $('.js-toggle-button').addClass(className)
        })
    }

    switchOffBurger("main-nav__toggle--active");
    switchOffBurger("nav__toggle--active");

    /* Кнопка меню-бургера */
    var toggle = document.querySelector(".js-toggle-button")

    function toggleHandler(toggle, className) {
        toggle.addEventListener("click", function (e) {
            e.preventDefault();
            (this.classList.contains(className) === true) ?
                this.classList.remove(className) :
                this.classList.add(className);
        });
    }

    toggleHandler(toggle, "nav__toggle--active");
    toggleHandler(toggle, "main-nav__toggle--active");

    //Видео по ховеру
    if (document.documentElement.clientWidth > 1200) {
        var card = $(".main-section__link");
        card.map(function (i, item) {
            var video = item.querySelector("video");
            if (video) {
                video.load();
                item.addEventListener('mouseover', function () {
                    video.play();
                }, false);
                item.addEventListener('mouseout', function () {
                    video.pause();
                }, false);
            }
        });
    }

    //BxSlider
    var $slider = $('.bxslider');

    if ($slider.length) {
        $slider.bxSlider({
            nextText: '',
            prevText: '',
            pagerCustom: '.bx-miniatures',
            controls: true
        });
    }

    //LG
    var $gallery = $(".lightgallery__wrapper");

    if ($gallery.length) {
        $gallery.lightGallery({
            selector: "a"
        });
    }

    //tables wrapper
    var $contentTables = $("table", ".textpage__content");

    if ($contentTables.length) {
        $contentTables.each(function () {
            $(this).wrap('<div class="table-wrap">');
        });
    }

    $('.share__links a').on('click', function (e) {
        e = (e ? e : window.event);
        var t = (e.target ? e.target : e.srcElement),
            width = t.data - width || 800,
            height = t.data - height || 500;

        // popup position
        var px = Math.floor(((screen.availWidth || 1024) - width) / 2),
            py = Math.floor(((screen.availHeight || 700) - height) / 2);

        // open popup
        var popup = window.open(t.href, "social",
            "width=" + width + ",height=" + height +
            ",left=" + px + ",top=" + py +
            ",location=0,menubar=0,toolbar=0,status=0,scrollbars=1,resizable=1");

        if (popup) {
            popup.focus();
            if (e.preventDefault) e.preventDefault();
            e.returnValue = false;
        }

        return !!popup;
    });

    var paginator = $('.js-paginator');
    if (paginator.length) {
        var container = $('.' + paginator.data('container')),
            replace_links = $('.js-paginator-replace, .' + paginator.data('replacer')),
            replacer_active = paginator.data('replacer-active'),
            preloader = $('.js-paginator-preloader'),
            next = paginator.data('next'),
            paginator_cache = new Map()
            ;

        if (next) {
            function getData(url, cb, replaceSate) {
                preloader.show();
                paginator.hide();
                if (replaceSate) {
                    history.replaceState({}, null, url);
                }
                if (paginator_cache.has(url)) {
                    var resp = paginator_cache.get(url);
                    if (cb) {
                        cb(resp.html)
                    }
                    if (resp.next && next !== resp.next) {
                        next = resp.next;
                        paginator.show();
                    }
                    preloader.hide();
                } else {
                    $.post(url, function (resp) {
                        if (resp.success) {
                            paginator_cache.set(url, resp);
                            if (cb) {
                                cb(resp.html)
                            }
                            if (resp.next && next !== resp.next) {
                                next = resp.next;
                                paginator.show();
                            }
                        } else {
                            alert('Произошла ошибка');
                        }
                        preloader.hide();
                    })
                }
            }

            function replaceData(url) {
                $('body, html').animate({
                    scrollTop: 0
                }, 600);
                getData(url, function (html) {
                    var $html = $(html);
                    $html.find('.' + paginator.data('replacer')).on('click', function (e) {
                        e.preventDefault();
                        replaceData(this.href);
                    });
                    container.html($html);
                    $('.' + replacer_active).removeClass(replacer_active);
                    $('.js-paginator-replace[href="' + location.pathname + '"]').addClass(replacer_active);
                }, true)
            }

            replace_links.on('click', function (e) {
                e.preventDefault();
                replaceData(this.href);
            });

            paginator.on('click', function (e) {
                e.preventDefault();
                getData(next, function (html) {
                    var $html = $(html);
                    $html.find('.' + paginator.data('replacer')).on('click', function (e) {
                        e.preventDefault();
                        replaceData(this.href);
                    });
                    container.append($html);
                }, false)
            })
        } else {
            paginator.hide();
        }
    }
});
